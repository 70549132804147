.responsive-card {
  overflow: auto;
  width: 100%;
}

.action-icon {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  cursor: 'pointer';
}

.color-primary {
  color: var(--antd-primary-color);
}

.color-success {
  color: var(--antd-success-color);
}

.color-danger {
  color: var(--antd-error-color);
}

/* start - antd*/

.ant-pagination {
  width: 100%;
  text-align: center;
}

/* end - antd */
