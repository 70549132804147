@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


/* Palette */

:root {
  --main-dark-color: #24477E;
  --antd-primary-color: #1890ff;
  --antd-success-color: #52c41a;
  --antd-error-color: #ff4d4f;
  --main-light-color: #88D0DF;
  --main-background-color: #6D7F9D;
  --main-black-color: #221E1E;
  --main-white-color: #FFF;

  /* Menu */
  --main-gradient-color: #2F9AFE;
  --main-light-grey-color: #BABCC3;
}
