.menu-gradient-color {
  background: rgb(64,89,134) !important;
  background: linear-gradient(0deg, rgba(64,89,134,1) 0%, rgba(64,89,134,1) 50%, rgba(70,102,158,1) 100%) !important;
}

.profile-card {
  color: var(--main-white-color) !important;
  background-color: var(--main-background-color) !important;
  border: 2px solid var(--antd-primary-color) !important;
  margin: 4px;
}

.menu .ant-menu-item {
  color: var(--main-light-grey-color) !important;
  background-color: transparent !important;

  display: flex;
  align-items: center;
}

.menu-font-family {
  font-family: 'Darker Grotesque' !important;
}

.menu .ant-menu-item-selected {
  color: var(--main-white-color) !important;
  background-color: var(--main-background-color) !important;
  border: 2px solid var(--antd-primary-color);
}

.menu .ant-menu-item:hover, .menu .ant-menu-item-selected {
  font-weight: 700;
}

.menu .ant-menu-item:not(:last-child):nth-last-child(2) {
  margin-top: 24px;
  margin-bottom: 24px;
}
